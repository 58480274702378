import React from 'react';

const WalletConnect = () => (
  <svg width={26} height={17} fill="none">
    <path
      d="M5.323 3.158c4.24-4.21 11.114-4.21 15.354 0l.51.507a.537.537 0 010 .763L19.443 6.16a.273.273 0 01-.384 0l-.702-.697c-2.958-2.938-7.754-2.938-10.712 0l-.752.747a.273.273 0 01-.384 0L4.763 4.477a.537.537 0 010-.762l.56-.557zm18.964 3.586l1.554 1.543a.537.537 0 010 .762l-7.005 6.958a.545.545 0 01-.768 0l-4.972-4.938a.136.136 0 00-.192 0l-4.972 4.938a.545.545 0 01-.767 0L.159 9.049a.537.537 0 010-.762l1.554-1.543a.546.546 0 01.767 0l4.972 4.938a.136.136 0 00.192 0l4.972-4.938a.545.545 0 01.768 0l4.972 4.938a.136.136 0 00.192 0l4.972-4.938a.545.545 0 01.767 0z"
      fill="#3B99FC"
    />
  </svg>
);

export default WalletConnect;
